<template>
  <div class="subscription">
    <Header />
    <HeaderBottom />
    <b-container class="subscription-container">
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <div class="row justify-content-center mx-auto my-auto">
            <div class="text-center">
              <img src="@/assets/img/loading/loading.gif" width="100px" />
            </div>
          </div>
        </template>
        <b-row class="justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div>
              <b-card
                class="product-information mb-4 d-none d-lg-block d-md-block"
              >
                <div class="fund-name">
                  <b-row>
                    <b-col cols="2">
                      <!-- <div v-if="product.image == null">
                        <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                          product.fund_name
                          " />
                      </div>
                      <div v-else>
                        <img :src="`${imageCore}/images/products/${product.image}`" alt="Fund Image" />
                      </div> -->
                      <img
                        :src="product.investment_manager.logo"
                        size="2rem"
                        alt="Fund Image"
                      />
                    </b-col>
                    <b-col>
                      <h4>
                        {{ product.fund_name }}
                      </h4>
                      <p v-text="product.investment_manager.im_name"></p>
                    </b-col>
                  </b-row>
                </div>

                <div class="fund-type">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Jenis Reksa Dana</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">{{ product.fund_type }}</p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="release-date">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Tanggal Peluncuran</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ moment(product.launch_date).format(" DD MMM YYYY") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-buy">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy }}{{ formatPrice(product.min_buy) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-sell">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Penjualan Kembali</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy
                        }}{{ formatPrice(product.min_sell) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="custodian-bank">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Bank Kustodian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <div>
                        <p class="custodian-bank-name">
                          {{ product.custodian_bank.cb_name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <template v-if="this.check == false">
                  <b-button
                    block
                    :to="{ path: '/' }"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.individual.ifua === null">
                  <b-button
                    block
                    @click="checkData"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template
                  v-else-if="
                    s21Client &&
                    s21Client.suspendbuy &&
                    s21Client.suspend &&
                    s21Client.suspendbuy === 1 &&
                    s21Client.suspend === 1
                  "
                >
                  <b-button
                    block
                    @click="checkData2"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.institutional != null">
                  <b-button
                    block
                    href="#"
                    disabled
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.individual.is_fbonds == 1">
                  <b-button
                    block
                    @click="isOpenModalNotRegister = 1"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else>
                  <template v-if="user.individual != null">
                    <template
                      v-if="user.individual.investors_risk_profile != null"
                    >
                      <template
                        v-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                            'low' &&
                          product.fund_type.toLowerCase() == 'pasar uang'
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template
                        v-else-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                            'moderate' &&
                          (product.fund_type.toLowerCase() == 'terproteksi' ||
                            product.fund_type.toLowerCase() ==
                              'penyertaan terbatas' ||
                            product.fund_type.toLowerCase() == 'campuran' ||
                            product.fund_type.toLowerCase() ==
                              'pendapatan tetap' ||
                            product.fund_type.toLowerCase() == 'pasar uang')
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template
                        v-else-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                          'high'
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template v-else>
                        <b-button
                          block
                          v-b-modal.modal-risk-profile
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                    </template>
                    <template v-else>
                      <b-button
                        block
                        href="/profil-risiko"
                        class="mt-2 btn-tertiary py-2"
                        >Beli</b-button
                      >
                    </template>
                  </template>
                  <template v-else>
                    <b-button
                      block
                      v-b-modal.modal-risk-profile
                      class="mt-2 btn-tertiary"
                      >Beli</b-button
                    >
                    <b-button
                      block
                      :href="'/subscription/' + product.fund_code"
                      class="mt-2 btn-tertiary"
                      >Beli</b-button
                    >
                  </template>
                </template>
              </b-card>
              <b-card
                class="product-information mb-4 d-block d-lg-none d-md-none"
              >
                <div class="fund-name">
                  <b-row>
                    <b-col cols="2">
                      <!-- <div v-if="product.image == null"> -->
                      <img
                        :src="product.investment_manager.logo"
                        size="2rem"
                        alt="Fund Image"
                      />
                      <!-- </div>
                      <div v-else>
                        <img :src="`${imageCore}/images/products/${product.image}`" alt="Fund Image" />
                      </div> -->
                    </b-col>
                    <b-col>
                      <h4>
                        {{ product.fund_name }}
                      </h4>
                      <p v-text="product.investment_manager.im_name"></p>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
              <!-- Hide Biaya Transaksi -->
              <!-- <b-card class="transaction-cost mb-4 d-none d-lg-block d-md-block">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <img src="@/assets/img/icons/product/transaction-cost-icon.svg" alt="Biaya Transaksi"
                          class="mr-2" />Biaya Transaksi
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_buy_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="redeem-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Penjualan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_sell_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="switching-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pengalihan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_switch_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
              </b-card> -->
              <!-- (End) Hide Biaya Transaksi -->

              <b-card class="transaction-cost d-block d-lg-none d-md-none">
                <b-row class="fund-performance text-left">
                  <div class="col-6">
                    <div class="nab mb-2">
                      <p>NAV/Unit</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="nab-value">
                      <p>
                        <span>{{ product.fund_ccy }}</span
                        >{{ formatNab(nav_history?.nav_per_unit) }}
                      </p>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="aum mb-2">
                      <p>Dana Kelolaan</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="aum-value">
                      <p>
                        <span>{{ product.fund_ccy }}</span
                        >{{ formatPrice(nav_history?.navAum) }}
                      </p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="participation-unit mb-2">
                      <p>Unit Penyertaan</p>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="participation-unit-value">
                      <p>
                        {{ formatNab(nav_history?.navUnit)
                        }}<span class="ml-1">unit</span>
                      </p>
                    </div>
                  </div>
                </b-row>
              </b-card>

              <!-- <b-card
                class="transaction-cost mb-4 d-none d-lg-block d-md-block"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <b-icon
                          class="mr-2 text-muted"
                          icon="credit-card-2-back"
                        ></b-icon
                        >Rekening Pembelian
                      </p>
                    </div>
                  </div>
                </b-row>
                <div>
                  <div
                    class="custodian-bank"
                    v-for="(value, index) in product.rekening"
                    :key="index"
                  >
                    <b-row>
                      <div class="col-lg-3 col-md-6">
                        <template>
                          <div v-if="value.bank.logo == null">
                            <img
                              :src="
                                'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                value.fund_name
                              "
                            />
                          </div>
                          <div v-else>
                            <img
                              :src="`${imageCore}/${value.bank.logo}`"
                              alt="Fund Image"
                              width="60px"
                            />
                          </div>
                        </template>
                      </div>
                      <div class="col-lg-9 col-md-6">
                        <template>
                          <p class="value my-auto">
                            {{
                              value.bank.bank_name ? value.bank.bank_name : "-"
                            }}
                          </p>
                        </template>
                      </div>
                      <div class="col-lg-3 col-md-6"></div>
                      <div class="col-lg-9 col-md-6 my-2">
                        <template>
                          <p class="value my-auto">
                            {{
                              value.account_number ? value.account_number : "-"
                            }}
                          </p>
                        </template>
                      </div>
                      <div class="col-lg-3 col-md-6"></div>
                      <div class="col-lg-9 col-md-6">
                        <template>
                          <p class="value">
                            {{ value.account_name ? value.account_name : "-" }}
                          </p>
                        </template>
                      </div>
                    </b-row>
                  </div>
                </div>
              </b-card> -->

              <b-card
                class="downloadable-document mb-4 d-none d-lg-block d-md-block"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <img
                          src="@/assets/img/icons/document/document-icon.svg"
                          alt="Lihat Dokumen"
                          class="mr-2"
                        />Lihat Dokumen
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="prospectus">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Prospektus</p>
                    </b-col>
                    <b-col>
                      <b-link
                        style="text-decoration: none"
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                      >
                        <p class="value">
                          <!-- <img src="@/assets/img/icons/download/download-icon.svg" alt="Disclaimer"
                            class="mx-2" /> -->
                          Lihat
                        </p>
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="fund-fact-sheet">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Fund Fact Sheet</p>
                    </b-col>
                    <b-col>
                      <b-link
                        :href="
                          imageCore +
                          '/fund_fact_sheet/download/' +
                          product.fund_fact_sheet
                        "
                      >
                        <p class="value">
                          <!-- <img src="@/assets/img/icons/download/download-icon.svg" alt="Download"
                            class="mx-2" /> -->
                          Lihat
                        </p>
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card class="terms d-none d-lg-block d-md-block">
                <div class="price-terms">
                  <b-row>
                    <div class="col-lg-12 col-md-6">
                      <p>
                        <img
                          src="@/assets/img/icons/product/transaction-rules.svg"
                          alt="Ketentuan"
                          class="mr-2"
                        />Ketentuan
                      </p>
                    </div>
                  </b-row>
                </div>
                <div class="transaction-time-before">
                  <b-row class="mt-2">
                    <!-- <div class="col-lg-12 col-md-6">
                      <p class="before">Transaksi sebelum 13:00 WIB</p>
                    </div> -->
                    <div class="col-lg-12 col-md-6">
                      <p class="before-detail">
                        Batas waktu konfirmasi pembelian Reksa Dana paling
                        lambat diterima dan tercatat oleh platform iTrade Fund
                        adalah sebelum pukul 13:00 WIB untuk diproses
                        menggunakan harga NAV/Unit di hari bursa yang sama.
                        <!-- <br /><br /> -->
                        Transaksi Pembelian yang diterima dan tercatat melebihi
                        batas waktu pukul 13:00 WIB akan diproses dengan
                        menggunakan harga NAV/Unit hari bursa berikutnya.
                      </p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-before ml-3">
                  <b-row class="mt-2">
                    <!-- <p class="before">Biaya dan Perpajakan</p> -->
                  </b-row>
                  <b-row>
                    <p class="before-detail">
                      Biaya dan Perpajakan akan timbul atas dasar ketentuan
                      peraturan perundangan-undangan yang berlaku, sesuai dengan
                      transaksi yang dimiliki.
                    </p>
                  </b-row>
                </div>
              </b-card>
            </div>
          </div>

          <div class="col-lg-8 col-md-6 product-performance-row">
            <div>
              <b-card
                class="product-performance mb-4 d-none d-lg-block d-md-block"
              >
                <b-row class="fund-performance">
                  <b-col class="mt-3">
                    <div class="nab mb-2">
                      <span>NAV/Unit</span>
                    </div>
                    <div class="nab-value">
                      <p>
                        <span class="mr-1">{{ product.fund_ccy }}</span
                        >{{ formatNab(nav_history?.nav_per_unit) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col class="mt-3">
                    <div class="aum mb-2">
                      <span>Dana Kelolaan</span>
                    </div>
                    <div class="aum-value">
                      <p>
                        <span class="mr-1">{{ product.fund_ccy }}</span
                        >{{ formatPrice(nav_history?.navAum) }}
                      </p>
                    </div>
                  </b-col>
                  <b-col class="mt-3">
                    <div class="participation-unit mb-2">
                      <span>Unit Penyertaan</span>
                    </div>
                    <div class="participation-unit-value">
                      <p>
                        {{ formatNab(nav_history?.navUnit)
                        }}<span class="ml-1">unit</span>
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="nav-chart mb-4 p-4">
                <b-row>
                  <b-col>
                    <h4>Performa NAV</h4>
                  </b-col>
                </b-row>
                <b-row>
                  <p>
                    Periode:
                    {{ moment(product.nav.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </b-row>
                <b-row class="justify-content-center mb-4">
                  <NavPerformanceChart :idProduct="parseInt(product_id)" />
                </b-row>
              </b-card>

              <b-card class="aum-chart p-4 mb-4">
                <b-row>
                  <b-col>
                    <h4>
                      Pertumbuhan Dana Kelolaan<span class="m-0 pl-2"
                        >(dalam satuan Miliar)</span
                      >
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <p>
                    Periode:
                    {{ moment(product.nav.nav_date).format(" DD MMM YYYY") }}
                  </p>
                </b-row>
                <b-row class="justify-content-center mb-4">
                  <AumPerformanceChart :idProduct="parseInt(product_id)" />
                </b-row>
              </b-card>

              <b-card
                class="product-information mb-4 d-block d-lg-none d-md-none"
              >
                <div class="fund-type">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Jenis Reksa Dana</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">{{ product.fund_type }}</p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="release-date">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Tanggal Peluncuran</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ moment(product.launch_date).format(" DD MMM YYYY") }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-buy">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy }}{{ formatPrice(product.min_buy) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="min-sell">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Min. Penjualan Kembali</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product.fund_ccy
                        }}{{ formatPrice(product.min_sell) }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="custodian-bank">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Bank Kustodian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <div>
                        <p class="custodian-bank-name">
                          {{ product.custodian_bank.cb_name }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card class="transaction-cost mb-4 d-block d-lg-none d-md-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <img
                          src="@/assets/img/icons/product/transaction-cost-icon.svg"
                          alt="Biaya Transaksi"
                          class="mr-2"
                        />Biaya Transaksi
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="subscription-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pembelian</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_buy_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="redeem-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Penjualan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_sell_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <!-- <div class="switching-cost">
                  <b-row class="mt-2">
                    <b-col class="my-auto">
                      <p class="variant">Biaya Pengalihan</p>
                    </b-col>
                    <b-col class="my-auto">
                      <p class="value">
                        {{ product ? product.fee_switch_text : "GRATIS" }}
                      </p>
                    </b-col>
                  </b-row>
                </div> -->
              </b-card>

              <!-- <b-card class="transaction-cost mb-4 d-block d-lg-none d-md-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <b-icon
                          class="mr-2 text-muted"
                          icon="credit-card-2-back"
                        ></b-icon
                        >Rekening Pembelian
                      </p>
                    </div>
                  </div>
                </b-row>
                <div v-for="(value, index) in product.rekening" :key="index">
                  <div class="subscription-cost">
                    <b-row class="mt-2">
                      <div class="col-3">
                        <template>
                          <div v-if="value.bank.logo == null">
                            <img
                              :src="
                                'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                                value.fund_name
                              "
                            />
                          </div>
                          <div v-else>
                            <img
                              :src="`${imageCore}/${value.bank.logo}`"
                              alt="Fund Image"
                              width="60px"
                            />
                          </div>
                        </template>
                      </div>
                      <div class="col-9">
                        <template>
                          <p class="value my-auto">
                            {{
                              value.bank.bank_name ? value.bank.bank_name : "-"
                            }}
                          </p>
                        </template>
                      </div>
                      <div class="col-lg-3 col-md-6"></div>
                      <div class="col-lg-9 col-md-6 my-2">
                        <template>
                          <p class="value my-auto">
                            {{
                              value.account_number ? value.account_number : "-"
                            }}
                          </p>
                        </template>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-9">
                        <template>
                          <p class="value">
                            {{ value.account_name ? value.account_name : "-" }}
                          </p>
                        </template>
                      </div>
                    </b-row>
                  </div>
                </div>
              </b-card> -->

              <b-card
                class="downloadable-document mb-4 d-block d-lg-none d-md-none"
              >
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="document">
                      <p>
                        <img
                          src="@/assets/img/icons/document/document-icon.svg"
                          alt="Lihat Dokumen"
                          class="mr-2"
                        />Lihat Dokumen
                      </p>
                    </div>
                  </div>
                </b-row>
                <div class="prospectus">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Prospektus</p>
                    </b-col>
                    <b-col>
                      <b-link
                        style="text-decoration: none"
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                      >
                        <p class="value">
                          <!-- <img src="@/assets/img/icons/download/download-icon.svg" alt="Disclaimer"
                            class="mx-2" /> -->
                          Lihat
                        </p>
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <div class="fund-fact-sheet">
                  <b-row class="mt-2">
                    <b-col>
                      <p class="variant">Fund Fact Sheet</p>
                    </b-col>
                    <b-col>
                      <b-link
                        :href="
                          imageCore +
                          '/fund_fact_sheet/download/' +
                          product.fund_fact_sheet
                        "
                      >
                        <p class="value">
                          <!-- <img src="@/assets/img/icons/download/download-icon.svg" alt="Download"
                            class="mx-2" /> -->
                          Lihat
                        </p>
                      </b-link>
                    </b-col>
                  </b-row>
                </div>
              </b-card>

              <b-card class="terms mb-4 p-4 d-block d-lg-none d-md-none">
                <div class="price-terms">
                  <b-row>
                    <div class="col-lg-12 col-md-6">
                      <p>
                        <img
                          src="@/assets/img/icons/product/transaction-rules.svg"
                          alt="Ketentuan"
                          class="mr-2"
                        />Ketentuan
                      </p>
                    </div>
                  </b-row>
                </div>
                <div class="transaction-time-before">
                  <b-row class="mt-2">
                    <div class="col-lg-12 col-md-6">
                      <p class="before">Transaksi sebelum 13:00 WIB</p>
                    </div>
                    <div class="col-lg-12 col-md-6">
                      <p class="before-detail">
                        Batas waktu konfirmasi pembelian Reksa Dana paling
                        lambat diterima dan tercatat oleh platform CGS iTrade
                        Fund adalah sebelum pukul 13:00 WIB untuk diproses
                        menggunakan harga NAV/Unit di hari bursa yang sama.
                      </p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-after">
                  <b-row class="mt-2">
                    <!-- <div class="col-lg-12 col-md-6">
                      <p class="after">Transaksi setelah 13:00 WIB</p>
                    </div> -->
                    <div class="col-lg-12 col-md-6">
                      <p class="after-detail">
                        Transaksi Pembelian yang diterima dan tercatat melebihi
                        batas waktu pukul 13:00 WIB akan diproses dengan
                        menggunakan harga NAV/Unit hari bursa berikutnya.
                      </p>
                    </div>
                  </b-row>
                </div>
                <hr />
                <div class="transaction-time-before ml-3">
                  <b-row class="mt-2">
                    <!-- <p class="before">Biaya dan Perpajakan</p> -->
                  </b-row>
                  <b-row>
                    <p class="before-detail">
                      Biaya dan Perpajakan akan timbul atas dasar ketentuan
                      peraturan perundangan-undangan yang berlaku, sesuai dengan
                      transaksi yang dimiliki.
                    </p>
                  </b-row>
                </div>
              </b-card>

              <div class="mb-4 d-block d-lg-none d-md-none">
                <template v-if="this.check == false">
                  <b-button
                    block
                    :to="{ path: '/' }"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.individual.ifua === null">
                  <b-button
                    block
                    @click="checkData"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template
                  v-else-if="
                    s21Client &&
                    s21Client.suspendbuy &&
                    s21Client.suspend &&
                    s21Client.suspendbuy === 1 &&
                    s21Client.suspend === 1
                  "
                >
                  <b-button
                    block
                    @click="checkData2"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.institutional != null">
                  <b-button
                    block
                    href="#"
                    disabled
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>
                <template v-else-if="user.individual.is_fbonds == 1">
                  <b-button
                    block
                    @click="isOpenModalNotRegister = true"
                    class="mt-2 btn-tertiary py-2"
                    >Beli</b-button
                  >
                </template>

                <template v-else>
                  <!-- <h1>aaa</h1> -->
                  <template v-if="user.individual != null">
                    <template
                      v-if="user.individual.investors_risk_profile != null"
                    >
                      <template
                        v-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                            'low' &&
                          product.fund_type.toLowerCase() == 'pasar uang'
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template
                        v-else-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                            'moderate' &&
                          (product.fund_type.toLowerCase() == 'terproteksi' ||
                            product.fund_type.toLowerCase() ==
                              'penyertaan terbatas' ||
                            product.fund_type.toLowerCase() == 'campuran' ||
                            product.fund_type.toLowerCase() ==
                              'pendapatan tetap' ||
                            product.fund_type.toLowerCase() == 'pasar uang')
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template
                        v-else-if="
                          user.individual.investors_risk_profile.toLowerCase() ==
                          'high'
                        "
                      >
                        <b-button
                          block
                          :href="'/subscription/' + product.fund_code"
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                      <template v-else>
                        <b-button
                          block
                          v-b-modal.modal-risk-profile
                          class="mt-2 btn-tertiary py-2"
                          >Beli</b-button
                        >
                      </template>
                    </template>
                    <template v-else>
                      <b-button
                        block
                        href="/profil-risiko"
                        class="mt-2 btn-tertiary py-2"
                        >Beli</b-button
                      >
                    </template>
                  </template>
                  <template v-else>
                    <b-button
                      block
                      v-b-modal.modal-risk-profile
                      class="mt-2 btn-tertiary"
                      >Beli</b-button
                    >
                    <b-button
                      block
                      :href="'/subscription/' + product.fund_code"
                      class="mt-2 btn-tertiary"
                      >Beli</b-button
                    >
                  </template>
                </template>
              </div>

              <!-- <div class="disclaimer">
                <b-row class="justify-content-center mb-4">
                  <div class="col-lg-12 col-md-6">
                    <div class="card p-1">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-lg-12 col-md-6 d-flex">
                            <div class="align-items-center">
                              <img src="@/assets/img/icons/information/information-ic.svg" alt="Disclaimer"
                                class="mr-2" />
                            </div>
                            <p>
                              Kinerja Reksa Dana yang ditampilkan pada halaman
                              ini merupakan kinerja historis masa lalu. Kinerja
                              masa lalu tidak serta-merta menjadi petunjuk untuk
                              kinerja di masa mendatang, dan bukan juga
                              merupakan perkiraan yang dibuat untuk memberikan
                              indikasi mengenai kinerja atau kecenderungannya di
                              masa mendatang
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>
              </div> -->
              <div class="mt-3">
                <i class="text-dark">
                  <div class="d-flex mb-5 px-3">
                    <div class="mr-2 text-nowrap">
                      <b>Peringatan :</b>
                    </div>
                    <span>
                      Investasi melalui Reksa Dana mengandung risiko dan kinerja
                      masa lalu tidak mencerminkan kinerja masa depan
                    </span>
                  </div>
                </i>
              </div>
            </div>
          </div>
        </b-row>

        <div class="risk-profile-modal p-4">
          <b-modal
            id="modal-risk-profile"
            size="lg"
            class="modal-risk-profile p-4"
            centered
            hide-footer
          >
            <div class="d-block text-center modal-text-header p-4">
              <div class="row justify-content-start text-left">
                <div class="col-lg-12 col-md-6">
                  <p class="disclaimer-text">
                    Produk yang anda pilih merupakan produk dengan risiko lebih
                    tinggi dibandingkan profil risiko anda.
                  </p>
                </div>
              </div>
            </div>
            <div class="row px-4">
              <div class="col-lg-12 col-md-6 d-flex">
                <div class="align-items-center">
                  <div class="custom-control custom-checkbox">
                    <input
                      id="agree"
                      type="checkbox"
                      value="accepted"
                      class="custom-control-input mr-2"
                      v-model="checked"
                    />
                    <label
                      for="agree"
                      class="custom-control-label text-justify"
                    >
                      Saya telah memahami, mengetahui, dan bertanggung jawab
                      atas segala risiko pembelian reksa dana diatas
                      karakteristik profil risiko saya. dan saya telah membaca
                      seluruh isi
                      <!-- Saya telah membaca, memahami, dan menyetujui seluruh isi -->
                      <b-link
                        :href="
                          imageCore +
                          '/prospectus/download/' +
                          product.prospectus
                        "
                        class="prospectus-link text-danger text-decoration-none"
                        target="_blank"
                        >Prospektus.</b-link
                      >
                      <!-- dan memahami risiko investasi yang saya buat. -->
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="this.user.individual != null">
              <b-row class="justify-content-center pb-4 px-3">
                <div class="col-lg-6 col-md-6">
                  <div v-if="!checked">
                    <b-button
                      block
                      :href="'/subscription/' + product.fund_code"
                      class="mt-3 mx-2 btn-outline-tertiary py-2"
                      :disabled="!checked"
                      >Setuju</b-button
                    >
                  </div>
                  <div v-else>
                    <b-button
                      block
                      :href="'/subscription/' + product.fund_code"
                      class="mt-3 mx-2 btn-tertiary py-2"
                      :disabled="!checked"
                      >Setuju</b-button
                    >
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <b-button
                    block
                    class="mt-3 mx-2 btn-outline-tertiary py-2"
                    @click="$bvModal.hide('modal-risk-profile')"
                    >Tidak Setuju</b-button
                  >
                </div>
              </b-row>
            </template>
          </b-modal>
        </div>
        <!-- Modal -->
        <div class="modal-register">
          <b-modal
            id="checkModal"
            ref="checkModal"
            size="md"
            class="modal-failed-register"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              />
              <h1 class="p-4 my-2 text-dark">Menunggu Verifikasi Akun</h1>
              <p>
                Mohon tunggu maksimal 2 hari kerja.<br />Setelah itu, Anda bisa
                mulai investasi.<br />Jangan lupa cek email, ya!
              </p>
              <b-row class="justify-content-md-end btn-submit-row mb-4">
                <div class="col-lg-12 col-md-6">
                  <b-button
                    size="sm"
                    class="btn-tertiary px-4 py-2"
                    @click="closeCheckData"
                    >Kembali</b-button
                  >
                </div>
              </b-row>
            </div>
          </b-modal>
          <b-modal
            id="checkModal2"
            ref="checkModal2"
            size="md"
            class="modal-failed-register"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              />
              <h1 class="p-4 my-2 text-dark">Akun Ditangguhkan</h1>
              <p>Akun anda sementara ditangguhkan</p>
              <b-row class="justify-content-md-end btn-submit-row mb-4">
                <div class="col-lg-12 col-md-6">
                  <b-button
                    size="sm"
                    class="btn-tertiary px-4 py-2"
                    click="closeCheckData2"
                    >Kembali</b-button
                  >
                </div>
              </b-row>
            </div>
          </b-modal>

          <!-- Syariah Prevent -->
          <b-modal
            v-model="modalSyariah"
            size="md"
            class="modal-failed-register"
            centered
            hide-footer
            hide-header
            no-close-on-backdrop
          >
            <div class="d-block text-center">
              <!-- <img
                src="@/assets/img/ekyc/verification-proccess.svg"
                alt="data-not-correct"
              /> -->
              <h1 class="p-4 my-2 text-dark">Tidak Diijinkan</h1>
              <p>Anda terdaftar sebagai nasabah Syariah</p>
              <b-row class="justify-content-md-end btn-submit-row mb-4">
                <div class="col-lg-12 col-md-6">
                  <b-button
                    size="sm"
                    class="btn-tertiary px-4 py-2"
                    @click="$router.back()"
                    >Kembali</b-button
                  >
                </div>
              </b-row>
            </div>
          </b-modal>
        </div>
      </b-skeleton-wrapper>
    </b-container>
    <Footer />
    <ModalNotRegister
      :isObligasi="false"
      :open="isOpenModalNotRegister"
      :closeHandler="closeHandlerModalNotRegister"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";
import NavPerformanceChart from "@/components/partials/product/navChart/NavPerformanceChart.vue";
import AumPerformanceChart from "@/components/partials/product/aumChart/AumPerformanceChart.vue";
import ModalNotRegister from "@/components/partials/modal/subscription/notRegister.vue";
import { encryptData } from "@/utils";

export default {
  name: "SubscriptionProductDetail",
  metaInfo: {
    title: "Detail Reksadana | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    NavPerformanceChart,
    AumPerformanceChart,
    Footer,
    ModalNotRegister,
  },
  data() {
    return {
      loading: false,
      loadingTime: 0,
      maxLoadingTime: 3,
      product: {
        investment_manager: {
          im_name: "",
        },
        custodian_bank: {
          cb_name: "",
        },
        fund_type: "",
        nav: {
          nav_per_unit: "",
          nav_date: "",
        },
      },
      fund_code: "",
      product_id: "",
      nav_history: {},
      arr_one_year: [],
      checked: false,
      imageCore: process.env.VUE_APP_IMAGE_URL,
      s21Client: null,

      // Modal Syariah
      modalSyariah: false,
      isOpenModalNotRegister: false
    };
  },

  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      user: "auth/user",
      app_url: "app_url",
      check: "auth/check",
      isUserSyariah: "auth/isUserSyariah",
    }),
  },
  mounted() {
    if (this.user?.individual?.client_id) {
      this.fetchS21Client();
    }
  },
  created() {
    this.fund_code = this.$route.params.fund_code;
    this.product_id = this.$route.params.id;

    Promise.all([this.fetchFund(), this.fetchFundCore()]).then(() => {
      if (this.isUserSyariah && this.product.is_sharia !== 1) {
        // this.$refs["modalSyariah"].show();
        // this.$bvModal.show("modalSyariah")
        this.modalSyariah = true;
      }
    });
    this.$_loadingTimeInterval = null;
  },
  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearLoadingTimeInterval();

        if (newValue) {
          this.$_loadingTimeInterval = setInterval(() => {
            this.loadingTime++;
          }, 1000);
        }
      }
    },
    loadingTime(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === this.maxLoadingTime) {
          this.loading = false;
        }
      }
    },
  },
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    async fetchFundCore() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `core/nav-history?api=1&type=1&fund_code=${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      this.nav_history = response?.data?.dataNav?.dataNav;
      this.loadingTime = 0;
    },

    async fetchFund() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}product/${this.fund_code}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      if (!response?.data?.data) {
        return this.$router.push("/NOT-FOUND");
      }
      this.product = response?.data?.data;
      this.loadingTime = 0;
    },
    async fetchS21Client() {
      this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}s21client?clientId=${encryptData(
          this.user?.individual?.client_id
        )}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.s21Client = response?.data?.data;
      this.loadingTime = 0;
    },

    checkData() {
      if (this.$refs["checkModal"]) this.$refs["checkModal"].show();
    },
    checkData2() {
      if (this.$refs["checkModal2"]) this.$refs["checkModal2"].show();
    },
    closeCheckData() {
      if (this.$refs["checkModal"]) this.$refs["checkModal"].hide();
    },
    closeCheckData2() {
      if (this.$refs["checkModal2"]) this.$refs["checkModal2"].hide();
    },
    closeHandlerModalNotRegister() {
      this.isOpenModalNotRegister = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}

.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #6b7280;
  }
}

.minimum-subscription {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
    color: #1f2937;
  }
}

.redeem-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
    color: #1f2937;
  }
}

.switching-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
    color: #1f2937;
  }
}

.release-date {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.min-buy {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.min-buy-next {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.min-sell {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.fee {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.fund-type {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #1f2937;
    text-align: right;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #4b5563;
  }

  .custodian-bank-name {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #1f2937;
    text-align: right;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #1f2937;
    text-decoration: none;
  }
}

.nab span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  background: #fcddd9;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.nab p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  background: #fcddd9;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.nab-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;
    color: #9ca3af;
    vertical-align: text-top;
  }
}

.aum span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5c0dab;
  background: #ebd9fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.aum p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #5c0dab;
  background: #ebd9fc;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.participation-unit span {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #0b318f;
  background: #b4c9ff;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.participation-unit p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #0b318f;
  background: #b4c9ff;
  border-radius: 20px;
  padding: 4px 12px 4px 12px;
}

.aum-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;
    color: #9ca3af;
    vertical-align: text-top;
  }
}

.participation-unit-value {
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4286px;
    color: #9ca3af;
  }
}

.document p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #111827;
}

.prospectus {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }

  a .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    color: #4b5563;
    text-align: right;
    text-decoration: none;
  }
}

.fund-fact-sheet {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: right;
    color: #4b5563;
    text-align: right;
  }

  a {
    text-decoration: none;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }

  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }

  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4b5563;
  }
}

.nav-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
    margin-left: 15px;
  }

  .nav-link {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    background: #f7f7f8;
    color: #4b5563;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-link:hover {
    background: $secondaryColor;
    color: white;
  }
}

.aum-chart {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #111827;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
    margin-left: 15px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
    margin-left: 15px;
  }

  .nav-link {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    background: #f7f7f8;
    color: #4b5563;
    padding: 10px 40px 10px 40px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .nav-link:hover {
    background: $primaryColor;
    color: white;
  }
}

.risk-profile-modal {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}

.custom-checkbox:focus {
  box-shadow: none !important;
}

.disclaimer {
  .card {
    background: #ebf8ff;
    border: 1px solid #90cdf4;
    box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
      0px 1px 3px rgba(107, 114, 128, 0.1);
    border-radius: 6px;
  }

  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #2c5282;
    margin: 0px;
  }
}

.modal-header {
  border-bottom: none !important;
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 11px !important;
  }

  .product-performance-row {
    margin-top: 30px;
  }

  .fund-performance {
    text-align: center;
  }

  .btn-tertiary {
    font-size: 14px !important;
  }

  .disclaimer {
    p {
      font-size: 14px;
    }
  }

  .disclaimer-text {
    font-size: 16px !important;
  }

  label {
    font-size: 14px !important;
  }

  .nab p {
    font-size: 11px;
  }

  .nab-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }

  .aum p {
    font-size: 11px;
  }

  .participation-unit p {
    font-size: 11px;
  }

  .aum-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }

  .participation-unit-value {
    p {
      font-size: 14px;
    }

    span {
      font-size: 11px;
    }
  }
}
</style>
